import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Typography } from '../../node_modules/@material-ui/core/index';

const BoatcloudMigration = () => {
    useEffect(() => {
        setTimeout(() => {
            const scriptId = 'knack-script';
            if (typeof window != 'undefined') {
                window.app_id = "5f763b35c13bc24b98e9455a";
                window.distribution_key = "dist_6";

                if (!document.getElementById(scriptId)) {
                    // Create the script element
                    const script = document.createElement('script');
                    script.src = 'https://loader.knack.com/5f763b35c13bc24b98e9455a/dist_6/knack.js';
                    script.type = 'text/javascript';
                    script.id = scriptId;

                    // Append the script to the document head
                    document.head.appendChild(script);

                    // Execute any specific logic after the script loads, if needed
                    script.onload = () => {
                        console.log('Knack script loaded successfully');
                        setTimeout(() => {
                            document.getElementById('kn-wrapp').classList.add('kn-wrapp-loaded');
                        }, 500);
                        // Add any post-load execution here
                    };
                }
            }
        }, 100);
    }, []);

    return (
        <Layout>
            <SEO title="BoatCloud Migration" description="Migrate to SpeedyDock" />
            <Box pb={8} pt={8}>
                <Container maxWidth="lg" style={{ minHeight: 500 }}>
                    <div id="kn-wrapp">
                        <div id="knack-dist_6" >
                            <br />
                            <Typography variant="h5">Loading Registration Form</Typography>
                            <br />
                            <Typography>Please Wait...</Typography>
                        </div>
                    </div>
                </Container>
            </Box>
            <style>{`.body-container-wrapper { min-height:auto !important; } .kn-wrapp-loaded { margin: 20px -20px }`}</style>
        </Layout>
    );
};

export default BoatcloudMigration;
